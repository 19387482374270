import * as React from "react"
import Layout from "../components/layout/layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container">
        <h2>Page not found</h2>
      </div>
    </Layout>
  )
}

export default NotFoundPage
